<template>
  <el-table ref="table" :data="data" style="width: 100%" @select="onSelect" @select-all="onSelect">
    <el-table-column type="selection" width="30">
    </el-table-column>
    <el-table-column :label="$tc('name', 1)">
      <template slot-scope="scope">
        {{ scope.row.addOnName }}
        <small v-if="scope.row.addOnBillRecurrence === 'ByPlan'">/ </small>
        <small v-if="scope.row.addOnBillRecurrence === 'Initial'">/ {{ $tc('paymentOnlyTime') }}.</small>
      </template>
    </el-table-column>
    <el-table-column
      :label="$tc('price')"
      property="addOnPrice"
      show-overflow-tooltip
      width="120"
    >
      <template slot-scope="scope">
        <el-input v-model.number="scope.row.addOnPrice" :placeholder="$tc('price')" class="input-currency">
          <i slot="prefix" class="el-input__icon el-icon-money"></i>
        </el-input>
      </template>
    </el-table-column>
    <el-table-column type="expand" width="30">
      <template slot-scope="scope">
        <small v-if="scope.row.addOnDescription">{{ $tc('description') }}: </small>
        <p>{{ scope.row.addOnDescription }}</p>
        <small>{{ $tc('payment') }}: </small>
        <p>
          {{ $tc($options.filters.toCamelCase(scope.row.addOnBillRecurrence)) }}
        </p>
        <small>{{ $tc('price') }}: </small>
        <p>{{ scope.row.addOnPrice | formatToCurrencyWithIsoCode }}</p>
        <small v-if="scope.row.addOnTaxPercentIncrease > 0">{{ $tc('taxIncrease') }}: </small>
        <p v-if="scope.row.addOnTaxPercentIncrease > 0">
          {{ scope.row.addOnTaxPercentIncrease }}
        </p>
        <small v-if="scope.row.addOnTaxPercentIncluded > 0">{{ $tc('taxIncluded') }}: </small>
        <p v-if="scope.row.addOnTaxPercentIncluded > 0">
          {{ scope.row.addOnTaxPercentIncluded }}
        </p>
      </template>
    </el-table-column>
  </el-table>
</template>
<script >

export default {
  name: 'BillAddOn',
  props: {
    data: {
      required: true,
      type: Array,
    },
    selected: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
    }
  },
  created() {
    const _this = this
    this.$nextTick(() => {
      if (_this.selected.length) {
        _this.selected.forEach(item => {
          const itemSelected = _this.data.find(itemAddOn => itemAddOn.id === item.id)
          if (itemSelected) {
            _this.$refs.table.toggleRowSelection(itemSelected)
          } else {
            _this.data.push(item)
            _this.$refs.table.toggleRowSelection(item)
          }
        })
      }
    })
  },
  methods: {
    onSelect(value) {
      // console.log('%c⧭', 'color: #731d1d', value)
      this.$emit('set-selected', value)
    },
  },
}
</script>
